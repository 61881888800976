import React, { useState, useEffect, useRef } from 'react';
import { Input, Button,Upload } from 'antd';
import { SendOutlined, UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { playgroundChat, playgroundFile } from '../../api';
import '../../App.css'; // Assuming you have a CSS file for styling
import DOMPurify from 'dompurify';
import { socket } from '../../socket';
import ActionModal from '../actionModal';


const ChatUI = ({ assistant }) => {
    const [messages, setMessages] = useState([]);
    const [totalTokens, setTotalTokens] = useState(0);
    const [newMessage, setNewMessage] = useState({ role: 'user', message: '' }); // Initialize newMessage as an object
    const messagesEndRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [actionData, setActionData] = useState(null);


    useEffect(() => {
        socket.on('message_playground', (message) => {
            console.log('message_playground:', message);
            setMessages(prevMessages => [...prevMessages, message]);
        });
        return () => {
            socket.off('message_playground');
        };
    }, []);

    const handleSendMessage = (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page

        // Sanitize the newMessage before adding it to the messages state
        const sanitizedMessage = { ...newMessage, message: DOMPurify.sanitize(newMessage.message) };
        
        setMessages(prevMessages => [...prevMessages, sanitizedMessage]);
        setNewMessage({ role: 'user', message: '' }); // Reset newMessage to an empty message with the role 'user'

        // Call the API to send the message to the assistant
        const messagesToSend = messages.filter(message => message.role !== 'action');
        playgroundChat(assistant, [...messagesToSend, sanitizedMessage])
            .then(response => {
                setTotalTokens(prevTokens => prevTokens + response.data.usage?.total_tokens);
                // const responseMessage = { role: 'assistant', message: response.data.message.message };
                setMessages(prevMessages => [...prevMessages, response.data]);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleUpload = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        const file = newFileList[0].originFileObj;
        playgroundFile(assistant._id, file)
            .then(response => {
                setTotalTokens(prevTokens => prevTokens + response.data.usage?.total_tokens);
                setMessages(prevMessages => [...prevMessages, response.data]);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleIconClick = (action) => {
        setActionData(action);
        setIsModalVisible(true);
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className="chat-container">
            <div className="messages-container" style={{display: 'flex', flexDirection: 'column', width: '100%', height: '50vh', backgroundColor: '#fafafa', overflow: 'auto'}}>
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.role}`} style={{whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column'}}>
                        <p className="message-content">{message.message}</p>
                        {message.role === 'assistant' ? <p className="date">{message?.model} - Tokens: {message.usage?.total_tokens}</p> : null}
                        {message.role === 'action' ? <Button icon={<InfoCircleOutlined />} onClick={() => handleIconClick(message.action)} /> : null}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Input.TextArea
                    value={newMessage.message}
                    onChange={e => setNewMessage({ role: 'user', message: e.target.value })}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            handleSendMessage(e);
                        }
                    }}
                    placeholder="Type a message"
                    style={{ height: '60px', fontSize: '18px', marginTop: '20px', marginBottom: '20px', marginRight: '10px'}}
                />
                <Upload
                    beforeUpload={() => false} // return false so antd doesn't auto upload the file
                    onChange={handleUpload}
                    fileList={fileList}
                >
                    <Button icon={<UploadOutlined />} style={{ height: '60px'}}>Upload File</Button>
                </Upload>
                <Button type="primary" onClick={handleSendMessage} style={{ height: '60px', fontSize: '18px', marginLeft: '10px' }}>
                    <SendOutlined />
                </Button>
            </div>
            <div style={{ textAlign: 'right', color: '#9b9b9b' }}>
                Total tokens used: {totalTokens}
            </div>
            <ActionModal 
                isVisible={isModalVisible} 
                handleOk={() => setIsModalVisible(false)} 
                handleCancel={() => setIsModalVisible(false)} 
                actionData={actionData} 
            />
        </div>
    );
};

export default ChatUI;