import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Card, Row, Col } from 'antd';
import { saveSugarCRMSettings, getSugarCRMSettings } from '../../api';

const SugarCRMPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [platform, setPlatform] = useState('');
    const [url, setUrl] = useState('');

    useEffect(() => {
        // Retrieve the SugarCRM settings when the component mounts
        getSugarCRMSettings()
            .then(response => {
                // Handle the response data
                if (response.data) {
                    const { username, password, platform, url } = response.data;
                    setUsername(username);
                    setPassword(password);
                    setPlatform(platform);
                    setUrl(url);
                }
            })
            .catch(error => {
                // Handle the error
                message.error('An error occurred while retrieving the settings.');
            });
    }, []);

    const handleSave = () => {
        // Send the settings to the server
        saveSugarCRMSettings({ username, password, platform, url })
            .then(response => {
                // Handle the response data
                message.success('Settings saved successfully!');
            })
            .catch(error => {
                // Handle the error\
                message.error(error.response.data.message);
            });
    };

    return (
        <div style={{ padding: '50px' }}>
            <h1 style={{ textAlign: 'left' }}>SugarCRM Integration</h1>
            <Row gutter={16}>
                <Col span={12}>
                    <Card>
                        <Form>
                            <Form.Item label="Username" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Input value={username} onChange={e => setUsername(e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Password" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Input.Password value={password} onChange={e => setPassword(e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Platform" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Input value={platform} onChange={e => setPlatform(e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Url" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Input value={url} onChange={e => setUrl(e.target.value)} />
                            </Form.Item>
                            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                <Button type="primary" onClick={handleSave}>Save</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default SugarCRMPage;