import React from 'react';
import { List, Avatar, Card, Button } from 'antd';
import { SettingOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const IntegrationsPage = () => {
    const navigate = useNavigate();

    const settings = [
        { name: 'ChatWidget Settings', path: '/integrations/chatwidget' },
        { name: 'SugarCRM Settings', path: '/integrations/sugarcrm' },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h1 style={{ textAlign: 'left' }}>Integrations</h1>
            </div>
            <Card style={{ width: '100%' }}>
            <List
                itemLayout="horizontal"
                dataSource={settings}
                renderItem={setting => (
                    <List.Item
                        actions={[
                            <Button 
                                icon={<EditOutlined />} 
                                onClick={() => navigate(setting.path)}
                            >
                                Edit
                            </Button>
                        ]}
                    >
                    <List.Item.Meta
                        style={{ alignItems: 'center' }}
                        avatar={<Avatar icon={<SettingOutlined />} />}
                        title={setting.name}
                    />
                    </List.Item>
                )}
            />
            </Card>
        </div>
    );
};

export default IntegrationsPage;