import React, { useEffect, useState } from 'react';
import withAuth from '../../withAuth';
import { getAssistants } from '../../api';
import { List, Avatar, Card, Button } from 'antd';
import { RobotOutlined, UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const AssistantsList = () => {
    const [assistants, setAssistants] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getAssistants()
            .then(response => {
                console.log(response.data);
                setAssistants(response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    const navigateToCreateAssistant = () => {
        navigate('/assistants/create');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h2 style={{ textAlign: 'left' }}>All Assistants</h2>
                <Button type="primary" icon={<UserAddOutlined />} onClick={navigateToCreateAssistant}>
                    Create Assistant
                </Button>
            </div>
            <Card style={{ width: '100%' }} >
            <List
                itemLayout="horizontal"
                dataSource={assistants}
                renderItem={assistant => (
                    <List.Item

                        actions={[
                            <Button 
                                icon={<EditOutlined />} 
                                onClick={() => navigate(`/assistants/${assistant._id}`)}
                            >
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            style={{ alignItems: 'center' }}
                            avatar={<Avatar icon={<RobotOutlined />} style={{ background: '#ff8317', color: '#fff' }}/>}
                            title={assistant.name}
                            description={`Assistant Id: ${assistant._id}`}
                        />
                    </List.Item>
                )}
            />
            </Card>
        </div>
    );
};

export default withAuth(AssistantsList);