import React from 'react';
import { Modal } from 'antd';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

const ActionModal = ({ isVisible, handleOk, handleCancel, actionData }) => {
    const data = JSON.stringify(actionData, null, 2);
    return (
        <Modal title="Action Details" open={isVisible} onOk={handleOk} onCancel={handleCancel} style={{ width: '530px' }}>
            <AceEditor
                style={{ width: '470px', height: '300px' }}
                mode="json"
                theme="monokai"
                name="actionData"
                editorProps={{ $blockScrolling: true }}
                value={data}
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                }}
                workerPath="/static/js"
            />
        </Modal>
    );
};

export default ActionModal;