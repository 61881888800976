// PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { DashboardOutlined, RobotOutlined, MessageOutlined } from '@ant-design/icons'; // Import the RobotOutlined icon from Ant Design
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');
    const location = useLocation();

    if (!token) {
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                <Menu mode="horizontal" style={{ display: 'flex', flex: 1}} selectedKeys={[location.pathname]} theme="dark">
                    <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="/assistants" icon={<RobotOutlined />}>
                        <Link to="/assistants">Assistants</Link>
                    </Menu.Item>
                    <Menu.Item key="/chat" icon={<MessageOutlined />}>
                        <Link to="/chat">Conversations</Link>
                    </Menu.Item>
                    {/* {localStorage.getItem('isAdmin') === 'true' ? (
                        <Menu.SubMenu key="integrations" title="Integrations" icon={<AppstoreAddOutlined />} className="multi-column-submenu" theme='light'>
                            <Menu.ItemGroup title="Models">
                                <Menu.Item key="chatgpt">
                                    <Link to="/integrations/chatgpt">ChatGPT</Link>
                                </Menu.Item>
                                <Menu.Item key="gemini">
                                    <Link to="/integrations/gemini">Gemini</Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Menu.ItemGroup title="Server Side">
                                <Menu.Item key="api">
                                    <Link to="/integrations/api">API</Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Menu.ItemGroup title="Client Side">
                                <Menu.Item key="chatwidget">
                                    <Link to="/integrations/chatwidget">Livechat</Link>
                                </Menu.Item>
                                <Menu.Item key="webhook">
                                    <Link to="/integrations/webhook">Webhook</Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                        </Menu.SubMenu>
                    ) : null} */}
                    <Menu.Item  style={{display: 'flex', flex: 1}} key=""></Menu.Item>
                    <UserMenu/>
                </Menu>
            </div>
            {children}
        </div>
    );
}

export default PrivateRoute;