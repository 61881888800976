import { useNavigate } from 'react-router-dom';
import { Menu, Avatar } from 'antd';
import { UserOutlined, LogoutOutlined, SettingOutlined, ApiOutlined, KeyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

function UserMenu() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        // <Menu mode="horizontal">
            <SubMenu key="SubMenu" icon={<Avatar icon={<SettingOutlined />} style={{ background: 'transparent' }}/>} theme='light'>
                {localStorage.getItem('isAdmin') === 'true' ? (
                    <>
                        <Menu.Item key="signup" icon={<UserOutlined />}>
                            <Link to="/users">Users</Link>
                        </Menu.Item>
                        <Menu.Item key="signup" icon={<KeyOutlined />}>
                            <Link to="/integrations/api">API Keys</Link>
                        </Menu.Item>
                        <Menu.Item key="signup" icon={<ApiOutlined />}>
                            <Link to="/integrations">Integrations</Link>
                        </Menu.Item>
                    </>
                ) : null}
                <Menu.Item key="logout" icon={<LogoutOutlined />}>
                    <Link to="/logout" onClick={handleLogout}>Logout</Link>
                </Menu.Item>
            </SubMenu>
        // </Menu>
    );
}

export default UserMenu;