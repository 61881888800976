import axios from 'axios';
// import dotenv from 'dotenv';
// dotenv.config();

const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;


export const isTokenValid = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/users`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

// Login API
export const login = (username, password) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        username,
        password
    });
};

export const signup = (firstName, lastName, email, phone, username, password, subdomain) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, {
        firstName, lastName, email, phone, username, password, subdomain
    });
};

export const verifyEmail = (token) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/auth/verify-email?token=` + token);
}

export const forgotPassword = (email) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, { 
        email 
    });
}

export const resetPassword = (token, password) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/auth/reset-password/${token}`, { 
        password 
    });
}

// Users API
export const getUsers = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/users`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const createUser = (firstName, lastName, email, phone, username, password, isAdmin, isVerified) => {
    const token = localStorage.getItem('token');
    return axios.post(`${API_URL}/users`, {
        firstName, lastName, email, phone, username, password, isAdmin, isVerified
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const getUser = (id) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/users/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(response => response.data);
};

export const editUser = (id, firstName, lastName, email, phone, username, password, isAdmin) => {
    const token = localStorage.getItem('token');
    return axios.put(`${API_URL}/users/${id}`, {
        firstName, lastName, email, phone, username, password, isAdmin
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(response => response.data);
};

export const deleteUser = (id) => {
    const token = localStorage.getItem('token');
    return axios.delete(`${API_URL}/users/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(response => response.data);
};

// Dashboard API
export const fetchDashboardData = async (startDate, endDate) => {
    const token = localStorage.getItem('token'); // Get the token from local storage
    const response = await fetch(`${API_URL}/dashboard?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
            'Authorization': 'Bearer ' + token  // Send the token as a Bearer token
        }
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
};

// Assistants API

export const getAssistants = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/assistants`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const createAssistant = (data) => {
    const token = localStorage.getItem('token');
    return axios.post(`${API_URL}/assistants`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const getAssistant = (id) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/assistants/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(response => response.data);
};

export const editAssistant = (id, data) => {
    const token = localStorage.getItem('token');
    return axios.put(`${API_URL}/assistants/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const deleteAssistant = (id) => {
    const token = localStorage.getItem('token');
    return axios.delete(`${API_URL}/assistants/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

// Playground API

export const playgroundChat = (assistant, message) => {
    const token = localStorage.getItem('token');
    return axios.post(`${API_URL}/assistants/playground`, {
        assistant, message
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const playgroundFile = (assistantId, file) => {
    const token = localStorage.getItem('token');
    let formData = new FormData();
    formData.append('assistantId', assistantId);
    formData.append('file', file);

    return axios.post(`${API_URL}/assistants/playground/file`, formData, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
};

// Conversations API

export const getConversations = (page) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/conversations/page/${page}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

export const updateConversation = (conversation, conversationId) => {
    const token = localStorage.getItem('token');
    return axios.put(`${API_URL}/conversations/${conversationId}`, conversation ,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
}

export const deleteConversation = (id) => {
    const token = localStorage.getItem('token');
    return axios.delete(`${API_URL}/conversations/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Messages API

export const getMessages = (conversationId) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/conversations/${conversationId}/messages`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Actions API

export const getActions = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/actions`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}


export const createAction = (data) => {
    const token = localStorage.getItem('token');
    return axios.post(`${API_URL}/actions`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

export const getAction = (id) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/actions/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

export const getActionsTemplates = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/actions/templates`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

export const editAction = (id, name, trigger, keywords, jsonObject, requestType, webhookURL, assistantId) => {
    const token = localStorage.getItem('token');
    return axios.put(`${API_URL}/actions/${id}`, {
        name, trigger, keywords, jsonObject, requestType, webhookURL, assistantId
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

export const deleteAction = (id) => {
    const token = localStorage.getItem('token');
    return axios.delete(`${API_URL}/actions/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Integrations API

// Get ChatGPT settings from the server
export const getChatGPTSettings = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/integrations/chatgpt`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Save ChatGPT settings to the server
export const saveChatGPTSettings = (settings) => {
    const token = localStorage.getItem('token');
    console.log('settings:', settings); 
    return axios.post(`${API_URL}/integrations/chatgpt`, settings, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Get Gemini settings from the server
export const getGeminiSettings = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/integrations/gemini`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Save Gemini settings to the server
export const saveGeminiSettings = (settings) => {
    const token = localStorage.getItem('token');
    console.log('settings:', settings); 
    return axios.post(`${API_URL}/integrations/gemini`, settings, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Get SugarCRM settings from the server
export const getSugarCRMSettings = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/integrations/sugarcrm`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Save SugarCRM settings to the server
export const saveSugarCRMSettings = (settings) => {
    const token = localStorage.getItem('token');
    console.log('settings:', settings); 
    return axios.post(`${API_URL}/integrations/sugarcrm`, settings, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Get API settings from the server
export const getApiSettings = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/integrations/api`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Save API settings to the server
export const saveApiSettings = (settings) => {
    const token = localStorage.getItem('token');
    return axios.post(`${API_URL}/integrations/api`, settings, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Get API keys from the server
export const getApiKeys = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/integrations/api/key`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Add a new API key to the server
export const addApiKey = (key) => {
    const token = localStorage.getItem('token');
    return axios.post(`${API_URL}/integrations/api/key`, key, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// Delete an API key from the server
export const deleteApiKey = (keyId) => {
    const token = localStorage.getItem('token');
    return axios.delete(`${API_URL}/integrations/api/key/${keyId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}


// Chat Widget API
export const getWidgets = () => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/integrations/chatwidget`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const getChatWidget = (id) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/integrations/chatwidget/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const addChatWidget = (config) => {
    const token = localStorage.getItem('token');
    return axios.post(`${API_URL}/integrations/chatwidget`, config, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const updateChatWidget = (id, config) => {
    const token = localStorage.getItem('token');
    return axios.put(`${API_URL}/integrations/chatwidget/${id}`, config, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const deleteChatWidget = (id) => {
    const token = localStorage.getItem('token');
    return axios.delete(`${API_URL}/integrations/chatwidget/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const checkIfEmbeddable = (website) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/puppeteer/check-embeddable?url=${website}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const getChatWidgetScreenshot = (website) => {
    const token = localStorage.getItem('token');
    return axios.get(`${API_URL}/puppeteer/screenshot?url=${website}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'blob'
    })
}